import * as React from 'react';

export default class Tallerista extends React.Component {
    public render(){

        const fotoURL = 'https://scontent.fmex11-1.fna.fbcdn.net/v/t1.0-9/81985889_10220971947478787_6761339260567027712_o.jpg?_nc_cat=101&_nc_sid=09cbfe&_nc_eui2=AeF_fkYnGoiz-gw1kJlfxfvvdnMbEZSLfvd2cxsRlIt-96ifA163RwXsZnoBDt6JZjNAcHQ8D3zdzq94B4lXyVmL&_nc_ohc=vapzFQVI968AX9Wws0O&_nc_ht=scontent.fmex11-1.fna&oh=2204522bba7f889c7812a4ec1ff574b8&oe=5F467F17';

        return (
            <div className="container-fluid tallerista">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Tallerista</h1>
                        </div>
                    </div>

                     <div className="row">
                        <div className="col-12">
                            <div className="img-wrapper">
                                <img src={fotoURL} alt=""/>
                            </div>
                        </div>
                    </div>

                     <div className="row">
                        <div className="col-12 nombre">
                            <h5>José Luis Zapata Torres</h5>
                            <p>Director general de la revista Reverberante</p>
                        </div>
                    </div>

                    <div className="line"></div>

                     <div className="row">
                        <div className="col-12 semblanza">
                            <p>Estudió Letras Hispánicas en la UAM Iztapalapa y
                                fue becario del Programa de Estímulo a la 
                                Creación y Desarrollo Artístico 2016. 
                                Fue el organizador del primer encuentro de escritores
                                jóvenes de la UAM Iztapalapa y ha impartido talleres de 
                                cuento para principiantes en la Facultad de Filosofía y 
                                Letras en la UAM Iztapalapa.
                                Actualmente, estudia el diplomado virtual de creación 
                                literaria del Instituto Nacional de Bellas Artes, es 
                                editor de la revista Asalto, publica en 
                                <span> Autopista del sol </span>(Praxis/SeCultura Guerrero 2017), 
                                es director general de la revista reverberante y coordinador de los
                                Talleres de formación artística Reverberante.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}