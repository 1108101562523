import * as React from 'react';

import Main from '../components/main';
import Objetivo from '../components/objetivo';
import Agenda from '../components/agenda';
import Tallerista from '../components/tallerista';
import Registro from '../components/registro';
import Contacto from '../components/contacto';
import Comentarios from '../components/comentarios';
import Resultados from '../components/resultados';

export default class Home extends React.Component {
    public render() {
        return (
            <div>
                <Main />
                <Objetivo />
                <Agenda />
                <Resultados />
                <Comentarios />
                <Registro />
                <Tallerista />
                <Contacto 
                fb = "https://drive.google.com/uc?export=view&id=1ylo2Z5ZqMPSV2_taYAucQSFVSO4CXmjt" 
                SocialMediaFbRever = "https://www.facebook.com/Reverberante-827137894328788/"/>
            </div>
        );
    }
}