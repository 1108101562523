import * as React from 'react';

interface ISocialMedia {
    fb: string,
    SocialMediaFbRever: string
}

export default class Contact extends React.Component<ISocialMedia> {
    public render(){
        const {fb} = this.props;
        const {SocialMediaFbRever} = this.props;

        return (
            <div className="container-fluid contacto">
                <div className="container">
                    
                    <div className="row title">
                        <div className="col-12">
                            <h1>Contáctanos</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p>Para dudas, aclaraciones, inscripciones y 
                                datos bancarios, favor de comunicarse a 
                                las siguientes direcciones:</p>
                            <p>Email: revista.reverberante@gmail.com</p>
                            <p>Whatsapp: 5560039338</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="social-media">
                                <a href={SocialMediaFbRever}>
                                    <img src={fb} alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}