import * as React from 'react';
import { connect } from 'react-redux';

import RegisterForm from './registerForm';

import { IRegister, Register as registerThunk } from '../ducks/Register';
import { ThunkDispatch } from 'redux-thunk';

import { Element } from 'react-scroll';

interface IRegisterProps {
    register: (a:IRegister) => void
}

class Registro extends React.Component<IRegisterProps> {

    public render(){
        const { register } = this.props;
        return (
            <Element name="Registro">
                <div className="container-fluid registro">
                    <div className="container">
                        
                        <div className="row">
                            <div className="col-12">
                                <h1>Registro</h1>
                            </div>
                        </div>

                        <div className="row pago">
                            <div className="col-12">
                                <p>$500 MXN - pago único en agosto</p>
                                <p>$700 MXN - en dos pagos de $350 MXN en los meses de agosto y septiembre</p>
                                <p>$35 USD - pago único para otros paises</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <RegisterForm onSubmit={register} />
                            </div>
                        </div>

                        <div className="row info">
                            <div className="col-12">
                                <p>El costo del taller incluye material audiovisual, lecturas, participación en el foro y sesiones del taller virtual.</p>
                                <p>La duración de las sesiones serán de 2 horas. Todos los sábados del 29 de agosto al 17 de octubre del 2020.</p>
                                <p>Las sesiones virtuales se harán a través de la plataforma Google Meet, por lo que no será necesario descargar ningún tipo de software.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </Element>
        )
    }
}

const mapStateToProps = (state: IRegister) => state;

const mapDispatchToProps = (dispatch:ThunkDispatch<any,any,any>) => ({
    register: (payload: any) => dispatch(registerThunk(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Registro)