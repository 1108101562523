import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

interface IInputProps {
    placeholder?: string,
    label?: string,
    name?: string,
    type?: string
}

const Input: React.StatelessComponent<IInputProps & WrappedFieldProps> = props => {
    //const { placeholder } = props;
    //const { name } = props;
    //const { type} = props;
    const { input, meta, ...prop} = props;
    return (
        <div className="input-wrapper">
            <input 
                {...input} {...prop} />
            {meta.submitFailed && meta.error && <span className="error-title">{meta.error}</span>}
            
        </div>
    )
}

export default Input