import * as React from 'react';
import { Link } from 'react-router-dom';

interface IUrlHeaderManager {
    logo:string,
    tweeter:string,
    fb:string,
    instagram:string
    SocialMediaFbRever:string,
    SocialMediaTweeterRever:string,
    SocialMediaInstagramRever:string
}

export default class Header extends React.Component<IUrlHeaderManager> {

    public render(){

        const {logo} = this.props
        
        const {tweeter} = this.props;
        const {fb} = this.props;
        const {instagram} = this.props;

        const {SocialMediaFbRever} = this.props;
        const {SocialMediaTweeterRever} = this.props;
        const {SocialMediaInstagramRever} = this.props;

        return (
            <header>
                <div className="logo-wrapper">
                    {/* Logo */} 
                    <Link to="/">
                        <img src={logo} alt=""/>
                    </Link>
                </div>
                <div className="social-wrapper">
                    {/* Redes sociales */}
                    <a href={SocialMediaFbRever}><img src={fb} alt=""/></a>
                    <a href={SocialMediaTweeterRever}><img src={tweeter} alt=""/></a>
                    <a href={SocialMediaInstagramRever}><img src={instagram} alt=""/></a>
                </div>
            </header>
        )
    }
}