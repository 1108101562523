import * as React from 'react';
import { Element } from 'react-scroll';

export default class Objetivo extends React.Component {
    public render(){
        return (
            <Element name="Objetivo">
                <div className="container-fluid objetivo">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h5>Estimular 
                                    la lectura y la escritura, 
                                    a través de actividades dinámicas
                                    y prácticas,
                                    con el fin de que el alumno tenga la capacidad para 
                                    crear en 
                                    libertad la historia
                                    que desea contar.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        );
    }
}