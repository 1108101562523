import * as React from 'react';

export default class Privacidad extends React.Component {
    public render() {
        return (
            <div className="container-fluid privacidad">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Aviso de privacidad</h1>
                        </div>
                    </div>

                    <div className="row content">
                        <div className="col-12">
                            <div className="text-wrapper">
                                <p>
                                    José Luis Zapata Torres, es el responsable del 
                                    tratamiento de los datos personales que nos proporcione.
                                </p>
                                <p>
                                    Los datos personales que recabamos de usted, 
                                    los utilizaremos para las siguientes finalidades: 
                                    Proporcionar información sobre novedades y promociones. 
                                    Invitaciones a eventos y convocatorias.
                                </p>
                                <p>
                                    En caso de que no desee que sus datos personales sean 
                                    tratados para las finalidades adicionales, usted puede 
                                    manifestarlo mediante correo electrónico a la dirección 
                                    de contacto.
                                </p>
                                <p>
                                    Si usted no manifiesta su negativa para dichas 
                                    transferencias, se entenderá que ha otorgado su 
                                    consentimiento.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center">
                    <div className="line"></div>                
                </div>
            </div>
        )
    }
}