import React from 'react';

import { Route } from 'react-router';

import './App.css';
import Header from './components/Header';
import Footer from './components/footer';
import Home from './containers/home';
import Privacidad from './containers/privacidad';
import Politica from './containers/politica';

interface socialMedia {
  
}

function App() {

  return (
    <div className="App">
      <Header
        logo = "https://drive.google.com/uc?export=view&id=1t_Hlwxb8ulkMKM1n8LZkk3wqETD5WDOI" 
         
        tweeter = "https://drive.google.com/uc?export=view&id=1zl8-CO-4j8gjBTyhSH3n3FGg9A4af0Xr" 
        fb = "https://drive.google.com/uc?export=view&id=1Ue9_P65yk7Q5Iij1YArK_9cGGP8uGt0A" 
        instagram = "https://drive.google.com/uc?export=view&id=11yOOV6j-XdgaPyn4MBtWNJn5xBZ9Xx9n"

        SocialMediaFbRever = "https://www.facebook.com/Reverberante-827137894328788/" 
        SocialMediaTweeterRever= "https://reverberante.com" 
        SocialMediaInstagramRever = "https://www.instagram.com/revistareverberante/"
      />
      
      <Route exact={true} path='/' component={Home} />
      <Route exact={true} path='/aviso-de-privacidad' component={Privacidad} />
      <Route exact={true} path='/politica-legal' component={Politica} />

      <Footer />
    </div>
  );
}

export default App;
