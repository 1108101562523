import * as React from 'react';

export default class Agenda extends React.Component {
    public render(){
        return (
            <div className="container-fluid agenda">
                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <h1>Contenido del taller</h1>
                            <p>El taller se encuentra dirigido al público
                                en general que desee de comenzar a escribir cuentos,
                                mejorar
                                sus habilidades de escritura o
                                adquirir los conceptos necesarios
                                para una mejor aprociación de textos
                                narrativos. 
                                No importa la edad o la experiencia
                                previa. 
                            </p>
                        </div>
                    
                    </div>

                    <div className="row topic-wrapper">
                        
                        <div className="col-12 ul-wrapper">
                            <ul>
                                <li>¿Cómo funcionan las historias?</li>
                                <li>¿Qué es un cuento?</li>
                                <li>¿Cómo construir tu propio cuento?</li>
                                <li>Narrador</li>
                                <li>Personajes</li>
                                <li>Espacio tiempo</li>
                            </ul>
                        </div>
                        {/*  
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 topic">
                            <div className="circle">
                                <span>1</span>
                            </div>
                            <p>¿Cómo funcionan las historias?</p>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 topic">
                            <div className="circle">
                                <span>2</span>
                            </div>
                            <p>¿Qué es un cuento?</p>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 topic">
                            <div className="circle">
                                <span>3</span>
                            </div>
                            <p>¿Cómo construir tu propio cuento?</p>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 topic">
                            <div className="circle">
                                <span>4</span>
                            </div>
                            <p>Narrador</p>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 topic">
                            <div className="circle">
                                <span>5</span>
                            </div>
                            <p>Personajes</p>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 topic">
                            <div className="circle">
                                <span>6</span>
                            </div>
                            <p>Espacio tiempo</p>
                        </div>
                        */}

                    </div>

                </div>                          
            </div>
        )
    }
}