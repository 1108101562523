import * as React from 'react';

export default class Comentarios extends React.Component {
    public render(){
        return (
            <div className="container-fluid comentarios">
                <div className="container">
                    <div className="row">
                        <div className="col-12 title">
                            <h1>Qué dicen nuestros alumnos</h1>
                        </div>
                    </div>
                    <div className="row cards-wrapper">

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="card">
                                <p className="quote"></p>
                                <p className="inner-text">
                                    "Muy contento y satisfecho con las sesiones.
                                    Definitivamente la lectura es una parte importante 
                                    de este taller. Me ha ayudado a darle forma o idea a 
                                    mi proyecto."
                                </p>
                                <div className="line"></div>
                            </div>
                            
                        </div>
                    
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="card">
                                <p className="quote"></p>
                                <p className="inner-text">
                                    "Me ha parecido un taller demasiado detallado
                                    y especializado, cada instructor/a que ha impartido 
                                    su módulo correspondiente está muy preparado/a. 
                                    No me arrepiento de haberlo elegido."
                                </p>
                                <div className="line"></div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="card">
                                <p className="quote"></p>
                                <p className="inner-text">
                                    "Me he sentido muy bien, pasa la semana y 
                                    lo único que me queda es esperar el sábado para 
                                    salir de mi rutina, el taller ha logrado eso 
                                    en mi vida. Es un espacio acogedor y enriquecedor."
                                </p>
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}