import { Dispatch } from "redux";
import { IServices } from '../services';

export interface IRegister {
    email: string,
    nombre: string,
    telefono: number
}

export default function reducer(state = {}){
    return state;
}

export const Register = ({ email, nombre, telefono }:IRegister) => 
    async (dispatch:Dispatch, getState: () => any, { functions }:IServices ) => {
        console.log(email);
        console.log(nombre);
        console.log(telefono);
        const query = '?name='+nombre + '&email='+email + '&phone='+String(telefono);
        const url = 'https://us-central1-reverberante-cdb7f.cloudfunctions.net/sendMail' + query;
        await fetch(url, {
            method:'GET',
            headers: new Headers()
        }).then(res => {
            console.log(res);
            if(res.status == 200){
                alert(nombre + ' se le ha enviado un correo electrónico a '+email);
            } else {
                alert('Ha ocurrido un error, por favor intente más tarde o trate de comunicarse a revista.reverberante@gmail.com');
            }
            
        })
        .catch(error => {
            //console.log(error);
            alert('Ha ocurrido un error, por favor intente más tarde o intente comunicarse a revista.reverberante@gmail.com');
        });
    }