import * as React from 'react';

export default class Resultados extends React.Component {
    public render(){
        return (
            <div className="container-fluid resultados">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Resultados</h1>

                            <p>Al final, dependiendo de la calidad de los cuentos creados
                                durante el taller, se publicarán en el sitio web de 
                                <a href="https://reverberante.com"> Reverberante.</a>
                            </p>
                            <p>Se entregarán constancias de participación.</p>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}