import * as React from 'react';
import { scroller } from 'react-scroll';

export default class Main extends React.Component {

    public scrollToObjetivo(){
        const scrollType = {
            duration: 500,
            delay:50,
            smooth: true
        };

        scroller.scrollTo('Objetivo', scrollType);
    }

    public scrollToRegistro(){
        const scrollType = {
            duration: 1000,
            delay:50,
            smooth: true
        };

        scroller.scrollTo('Registro', scrollType);
    }

    public render(){
        return (
            <div className="container-fluid main">
                <div className="text-wrapper row">
                    <div className="col-12">
                        <h1>Taller online de cuento para principiantes</h1>
                        <h4>Revista Reverberante</h4>
                        <p>Empezamos el 29 de agosto del 2020</p>
                    </div>
                </div>
                <div className="button-wrapper row">
                    <div className="col-12">
                        <button onClick={this.scrollToRegistro} className="register-button">
                            Registrarse
                        </button>

                        <button onClick={this.scrollToObjetivo} className="ver-mas-button">
                            Ver mas
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}