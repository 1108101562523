import * as React from 'react';
import { InjectedFormProps, reduxForm, Field } from 'redux-form';

import Input from './input';

import { IRegister } from '../ducks/Register';

const validate = (values:any) => {
    const errors:any = {}

    if(!values.nombre){ errors.nombre = 'Campo obligatorio';}

    if(!values.telefono){ errors.telefono = 'Campo obligatorio';}
    else if(isNaN(values.telefono)) { errors.telefono = 'Caracter no válido';}

    if(!values.email){ errors.email = 'Campo obligatorio';}
    else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
    {errors.email = 'Correo electrónico no válido';}

    return errors;
}

class RegisterForm extends React.Component<InjectedFormProps<IRegister>> {
    public render() {
        const { handleSubmit } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Field placeholder="Nombre" name="nombre" type="text" component={Input} />
                <Field placeholder="Email" name="email" type="email" component={Input} />
                <Field placeholder="Teléfono" name="telefono" type="tel" component={Input} />
                <input type="submit" value="Enviar" />
            </form>
        )
    }
}

export default reduxForm<IRegister>({
    form: 'registro',
    validate
})(RegisterForm)

