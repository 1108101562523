import * as React from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

export default class Footer extends React.Component {

    public onClickUp() {
        const scrollType = {
            duration: 500,
            delay:50,
            smooth: true
        };
        scroll.scrollToTop(scrollType);
    }

    public render(){

        return (
            <footer>

                <div className="links">
                    <Link onClick={this.onClickUp} to="/aviso-de-privacidad">Aviso de Privacidad</Link>
                    <Link onClick={this.onClickUp} to="/">Política legal</Link>
                </div>
                
                <div className="line"></div>

                <div className="container-fluid powered">
                    <p>Powered by <img src={process.env.PUBLIC_URL + '/trueno.png'} alt=""/> Alejandro Zapett</p>
                </div>

            </footer>
        )
    }
}