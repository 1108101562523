import * as firebase from 'firebase';
import { functions } from 'firebase';

const services = {
    ...firebase,
};

export interface IServices {
    functions: functions.Functions
}

export default services;